import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuth } from '../../auth'
import { TableOrders } from '../components/tables/TableOrders'
import { FilterOffCanvas } from '../components/offCanvas/FilterOfCanvas'
import OrderService from '../../../../services/order'
import useModal from '../../../../commons/hooks/useModal'
import IconSearch from '../../../../commons/icons/IconSearch'
import SearchFilter from '../../../../commons/components/search/SearchFilter'
import { usePaginator } from '../../../../commons/hooks/usePaginator'
import { IconMagnifier } from '../../../../commons/icons'

const pageSizes = [10, 20, 50]
export const ListOrder = () => {
  const { handleClose, handleShow, show } = useModal()
  const { eventSelectedCtx } = useAuth()
  const [filter, setFilter] = useState(eventSelectedCtx === 0 ? {} : { content_event_id: eventSelectedCtx })
  const [search, setSearch] = useState<string>('')
  const [notResult, setNotResult] = useState(false)
  const [notResultFilter, setNotResultFilter] = useState(false)
  const searchFilterRef = useRef<any>(null); // Referencia para el componente SearchFilter

  const methods = useForm()
  const {
    limit,
    setPage,
    paginationComponent,
    query: { data: orderList, refetch, isRefetching },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(1)!,
    pageSizes,
    filters: filter,
    search,
    paginatorOptions: {
      queryKey: ['orderList'],
      fetch: OrderService.list,
    },
  })

  useEffect(() => {
    setPage(1)
    const fetchData = async () => {
      const response = await refetch()
      setNotResult(response?.data?.data?.result?.data.length > 0 ? false : true)
      setNotResultFilter(
        response?.data?.data?.result?.data.length === 0 &&
        !(
          (Object.keys(filter).length === 1 && Object.keys(filter)[0] === 'content_event_id') ||
          Object.keys(filter).length === 0
        )
      )
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, filter, refetch, search])

  
  const handleSearch = () => {
    if(searchFilterRef) searchFilterRef.current.triggerSearch()
  }

  return (
    <div className=''>
      <div className='title'>Lista del Pedido</div>
      <div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap border-0 pt-6'>
        {/* begin::Search */}
        <div className='d-flex gap-3'>
           <SearchFilter manageSearch={setSearch} ref={searchFilterRef} name='pedido'/>
           <div className='p-1'>
           <button
              type='button'
              className='btn btn-primary'
              onClick={handleSearch}
            >
              <IconMagnifier stroke="white"/>
            </button>
           </div>
        </div>
        {/* end::Search */}
        <div className='row w-118px mt-4 mt-sm-0 d-flex'>
          <div>
            <button
              type='button'
              className='btn btn-outline btn-outline-primary w-100 '
              onClick={handleShow}
            >
              <IconSearch className='me-3' />
              Filtros
            </button>
          </div>
        </div>
      </div>

      <div className='mt-6'>
        <div className='overflow-auto table-list'>
            <TableOrders
              refetched={isRefetching}
              data={orderList?.data.result.data}
              isNotResult={notResult}
              isNotResultFilter={notResultFilter}
            />
        </div>
      </div>

      {notResult || notResultFilter ? null : paginationComponent()}

      {/* Filtros */}
      <div className='d-none'>
        <FormProvider {...methods}>
          <FilterOffCanvas
            filter={filter}
            setFilter={setFilter}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
          />
        </FormProvider>
      </div>
    </div>
  )
}

export default ListOrder;